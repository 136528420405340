section#VTarifs {
  .gridTarif {
    button {
      background-color: #FFFDFA;
    }

    .odd {
      background-color: #f2f2f2;
      color: #000 !important;

      li[role='separator'] {
        background-color: #000 !important;
      }

      .fill-button:before {
        background-color: #ABBEDE !important;
      }
    }
    .even {
      background-color: #ABBEDE;
      li[role='separator'] {
        background-color: #FFFDFA !important;
      }
      *:not(button) {
        color: #FFFDFA !important;
      }
      .fill-button:before {
        background-color: #D7D7D7 !important;
      }
    }

    .buttonPricing:hover {
      border: 1px solid #000 !important;
    }

    .fill-button {
      position: relative;
      display: inline-block;
      cursor: pointer;
      overflow: hidden;
      background-color: #FFFDFA;
      color: #6d6d6d !important;
      border: 1px solid #6d6d6d !important;
    }

    .fill-button::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      z-index: -1;
      transition: width 0.4s ease;
    }

    .fill-button:hover::before {
      width: 100%;
    }

    .fill-button:hover {
      color: #FFFDFA !important;
      z-index: 1;
    }

    .fill-button {
      position: relative;
      z-index: 1;
    }
  }
}