section#VEvents {
  * {
    color: #000;
  }
  ul {
    justify-content: flex-end;
    * {
      color: #000 !important;
    }
  }
  .MuiButton-root {
    border-color: #FFFDFA;
  }
  .MuiCard-root {
    background-color: #FFF;
  }

  .cardEvents {
    img {
      filter: drop-shadow(0px 0px 2px #000)
    }
  }
}

section#VCreateEvent {
  background-color: #FFFDFA;

  .MuiInput-root, .MuiAutocomplete-root  {
    background-color: #FFFDFA !important;
  }
  .public-DraftStyleDefault-block {
    margin: 0;
  }
  label {
    color: #000;
  }
  .cards_events {
    background-color: #FFF !important;
  }

  button.next {
    background-color: #ABBEDE !important;
  }
  button.prev {
    background-color: #FFFDFA !important;
    &:hover {
      background-color: #e5e5e5 !important;
    }
  }
  button.submit {
    background-color: #E691EE !important;
  }
}