html {
    background-color: #FFFDFA;
}

section#layout {
    div#sidebar {
        background: linear-gradient(90deg, #080917, #1b1e4d);
        color: #FFF;
    }

    nav.mainnav {
        background: linear-gradient(90deg, #1b1e4d, #080917);
        color: #FFFDFA;
        z-index: 100;
    }
}

.custom-bg-milk {
    background-color: #FFFDFA;
}