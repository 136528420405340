#VLogin {
  .login_logo {
    display: flex;
    gap: 1rem;
    align-items: center;

    img {
      height: 85px;
    }
  }

  .firstTitle {
    color: white;
  }

  .secondTitle {
    color: #FD401A;
  }

  .MuiInput-root * {
    color: initial !important;
  }

  .card {
    width: 560px;
    height: 400px;
    border-radius: 2rem;

    position: absolute;
    margin-top: 2rem;
    left: calc(50% - 280px);

    button[type="submit"] {
      background-color: #ABBEDE;
      transition: all ease-in .3s;
      &:hover {
        background-color: #343a9c;
      }
    }
  }
}