@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    background-color: #FFFDFA;
    .dark-bg {
        background: linear-gradient(90deg, #080917, #1b1e4d) !important;
        height: 101vh;
    }
}