section#VPaiementSucced {
  height: 100vh;
  background: linear-gradient(90deg, #080917, #1b1e4d) !important;

  .firstTitle {
    color: white;
  }

  .secondTitle {
    color: #C53012;
  }
}