section#VListUsers {
  .MuiPagination-ul {
    justify-content: flex-end;
  }

  table.MuiTable-root {
    text-align: center;
    th {
      background-color: #131537 !important;
      color: white !important;
      padding: 0.5rem !important;
      font-size: 1rem;
      text-align: center;

      &:first-child {
        border-top-left-radius: 5px !important;
      }

      &:last-child {
        border-top-right-radius: 5px !important;
      }
    }

    tbody tr:nth-child(even) {
      background: #EAEAEA !important;
    }
  }
}

section#VShowUser {
  .cards {
    background-color: #FFF !important;
  }
  .submitBtn {
    background-color: #C277C9;
  }
}